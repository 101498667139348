import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-validation-component',
  templateUrl: 'validation.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./validation.component.scss'],
})
export class ValidationComponent {
  @Input() displayName: string;
  @Input() formElement: FormControl;
  @Input() fileSize: number;

  constructor() {}
}
