import { OrderListItem } from 'src/app/shared/interface/order';
import { orderCategoryMap, orderTypeMap } from '../constant/order';
import { APIOrderTypes } from '../enums/order-types';

export const addDetailsUrlInOrder = (order: OrderListItem) => {
  order.detailsUrl = `/${orderTypeMap[order.type]}/${orderCategoryMap[order.category]}/details`;
  if (order.type.toString() === APIOrderTypes.edit) {
    order.detailsUrl = `/${orderTypeMap[order.type]}/details`;
  }
  return order;
};

export const addDetailsUrlInOrdersList = (list: OrderListItem[]) => {
  return list.map(addDetailsUrlInOrder);
};
