export enum DataTableActivity {
  VIEW = 'Details',
  EDIT = 'Edit',
  DELETE = 'Delete',
  CREATE = 'Create',
  PAY = 'Pay',
  CHECK = 'Check',
  INVOICE = 'Invoice',
  FEEDBACK = 'Feedback',
  CONVERT_ORDER_EMAIL = 'Send Convert to Order Email',
  FOLLOW_UP = 'FollowUp',
  FOLLOW_UP_COMMENT = 'FollowUpComment',
}

export enum DataTableCoulmnTypes {
  CURRENCY = 'currency',
  DATE = 'date',
  LOV = 'lov',
  COUNTRY = 'country',
  STATUS = 'status',
  PAYMENT_STATUS = 'paymentStatus',
  CONVERSION = 'conversionStatus',
  TYPE = 'type',
  CATEGORY = 'category',
  CUSTOMER = 'customer',
  CUSTOMER_COUNTRY = 'customer-country',
  AGENT = 'agent',
  DIGITIZER = 'digitizer',
  FOLLOW_UP = 'FollowUp',
}
