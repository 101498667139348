import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ToasterService {
  toastTimeout = 10000;

  constructor(private toastrService: ToastrService) {}

  success(message: string, title: string = 'Success!'): void {
    this.toastrService.success(message, title, {
      timeOut: this.toastTimeout,
    });
  }

  error(message: string): void {
    this.toastrService.error(message, 'Error', {
      timeOut: this.toastTimeout,
    });
  }

  warning(message: string): void {
    this.toastrService.warning(message, 'Warning', {
      timeOut: this.toastTimeout,
    });
  }

  info(message: string): void {
    this.toastrService.info(message, 'Info!', {
      timeOut: this.toastTimeout,
    });
  }

  clear(): void {
    this.toastrService.clear();
  }
}
