import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { RoleGuard } from '../core/guards/role.guard';
import { ShellComponent } from './shell.component';

// Constants
import { UserRoles } from '../shared/enums/role';

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: '../customer-dashboard/customer-dashboard.module#CustomerDashboardModule',
      },
      {
        path: 'user',
        loadChildren: '../user/user.module#UserModule',
      },
      {
        path: 'admin-user',
        loadChildren: '../admin-user/admin-user.module#AdminUserModule',
      },
      {
        path: 'digitizer',
        loadChildren: '../admin-user/admin-user.module#AdminUserModule',
      },
      {
        path: 'digitizing',
        loadChildren: '../digitizing/digitizing.module#DigitizingModule',
      },
      {
        path: 'vector',
        loadChildren: '../vector/vector.module#VectorModule',
      },
      {
        path: 'edit',
        loadChildren: '../edit/edit.module#EditModule',
      },
      {
        path: 'billing',
        loadChildren: '../billing/billing.module#BillingModule',
      },
      {
        path: 'all',
        loadChildren: '../order/order.module#OrderModule',
      },
      {
        path: 'public',
        loadChildren: '../static-pages/static-pages.module#StaticPagesModule',
      },
      {
        path: 'release',
        loadChildren: '../release/release.module#ReleaseModule',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShellRoutingModule {}
