import { Injectable } from '@angular/core';

// Enums
import { UserRoles } from 'src/app/shared/enums/role';

// Services
import { StorageService } from './storage.service';

@Injectable()
export class AppService {
  constructor(private storageService: StorageService) {}

  checkIfUserIsAdmin() {
    const roleId = this.storageService.getItem('user').roleId;
    return roleId === UserRoles.ADMIN;
  }

  checkIfUserIsManager() {
    const roleId = this.storageService.getItem('user').roleId;
    return roleId === UserRoles.MANAGER;
  }

  checkIfUserIsCustomer() {
    const roleId = this.storageService.getItem('user').roleId;
    return roleId === UserRoles.CUSTOMER;
  }

  checkIfUserisManagerOrAdmin() {
    const roleId = this.storageService.getItem('user').roleId;
    return roleId === UserRoles.ADMIN || roleId === UserRoles.MANAGER;
  }

  checkIfUserIsSeniorDigitizer() {
    const roleId = this.storageService.getItem('user').roleId;
    return roleId === UserRoles.SENIOR_DIGITIZER;
  }

  checkIfUserIsDigitizer() {
    const roleId = this.storageService.getItem('user').roleId;
    return roleId === UserRoles.DIGITIZER;
  }

  checkIfUserCanAccessFiles() {
    const canAccessFile = this.storageService.getItem('user').canAccessFile;
    return canAccessFile;
  }
}
