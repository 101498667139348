export enum OrderCategory {
  QUOTE = 'quote',
  ORDER = 'order',
}

export enum OrderTypes {
  DIGITIZING = 'digitizing',
  VECTOR = 'vector',
  EDIT = 'edit',
}

export enum APIOrderCategory {
  order = '1',
  quote = '2',
}

export enum APIOrderTypes {
  digitizing = '1',
  vector = '2',
  edit = '3',
}
