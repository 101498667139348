import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../core/service/storage.service';
import { EventEmitterService } from 'src/app/core/service/event-emitter.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header-component',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  isDropDownVisible = false;
  loggedInUser: any;
  toggleSidebar = false;
  appUrl: string = environment.appUrl;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private eventEmitterService: EventEmitterService,
  ) {}

  ngOnInit() {
    this.loggedInUser = this.storageService.getItem('user');
  }

  logOut() {
    this.storageService.remove('token');
    this.router.navigate(['/auth', 'login']);
  }

  toggleSidebarMenu() {
    this.toggleSidebar = !this.toggleSidebar;
    this.eventEmitterService.emitSidebarToggleChange(this.toggleSidebar);
  }
}
