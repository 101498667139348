import { UserRoles } from '../../shared/enums/role';
import { SideBarMenu } from './sidebar-menu.interface';

export const sidebarMenu: SideBarMenu[] = [
  {
    title: 'Dashboard',
    icon: 'mdi-television',
    route: '/dashboard',
    hasSubMenu: false,
    activeSubMenu: false,
  },
  {
    title: 'All Users',
    icon: 'mdi-account-multiple',
    route: '/user/list',
    role: [UserRoles.ADMIN, UserRoles.MANAGER],
    hasSubMenu: false,
    activeSubMenu: false,
  },
  {
    title: 'Unpaid Users',
    icon: 'mdi-account-multiple-outline',
    route: '/user/unpaid-users',
    role: [UserRoles.ADMIN, UserRoles.MANAGER],
    hasSubMenu: false,
    activeSubMenu: false,
  },
  {
    title: 'Inactive Users',
    icon: 'mdi-account-multiple-outline',
    route: '/user/inactive-users',
    role: [UserRoles.ADMIN, UserRoles.MANAGER],
    hasSubMenu: false,
    activeSubMenu: false,
  },
  {
    title: 'Sales Agents',
    icon: 'mdi-account-switch',
    role: [UserRoles.ADMIN],
    hasSubMenu: true,
    activeSubMenu: false,
    subMenuList: [
      {
        title: 'Create New Sales Agent',
        icon: 'fa-plus',
        route: '/admin-user/create',
      },
      {
        title: 'View All Sales Agents',
        icon: 'fa-list-ul',
        route: '/admin-user/list',
      },
    ],
  },
  {
    title: 'Digitizer',
    icon: 'mdi-account-switch',
    role: [UserRoles.ADMIN],
    hasSubMenu: true,
    activeSubMenu: false,
    subMenuList: [
      {
        title: 'Create New Digitizer',
        icon: 'fa-plus',
        route: '/admin-user/digitizer/create',
      },
      {
        title: 'View All Digitizer',
        icon: 'fa-list-ul',
        route: '/admin-user/digitizer/list',
      },
    ],
  },
  {
    title: 'All Digitizing Orders',
    icon: 'mdi-hexagon-outline',
    route: '/digitizing/order/list',
    role: [UserRoles.ADMIN, UserRoles.MANAGER, UserRoles.SENIOR_DIGITIZER, UserRoles.DIGITIZER],
    hasSubMenu: false,
    activeSubMenu: false,
  },
  {
    title: 'All Digitizing Quotes',
    icon: 'mdi-hexagon',
    route: '/digitizing/quote/list',
    role: [UserRoles.ADMIN, UserRoles.MANAGER, UserRoles.SENIOR_DIGITIZER, UserRoles.DIGITIZER],
    hasSubMenu: false,
    activeSubMenu: false,
  },
  {
    title: 'All Vector Orders',
    icon: 'mdi-guitar-pick-outline',
    route: '/vector/order/list',
    role: [UserRoles.ADMIN, UserRoles.MANAGER, UserRoles.SENIOR_DIGITIZER, UserRoles.DIGITIZER],
    hasSubMenu: false,
    activeSubMenu: false,
  },
  {
    title: 'All Vector Quotes',
    icon: 'mdi-guitar-pick',
    route: '/vector/quote/list',
    role: [UserRoles.ADMIN, UserRoles.MANAGER, UserRoles.SENIOR_DIGITIZER, UserRoles.DIGITIZER],
    hasSubMenu: false,
    activeSubMenu: false,
  },
  {
    title: 'All Edits',
    icon: 'mdi-pencil-box-outline',
    route: '/edit/list',
    role: [UserRoles.ADMIN, UserRoles.MANAGER, UserRoles.SENIOR_DIGITIZER, UserRoles.DIGITIZER],
    hasSubMenu: false,
    activeSubMenu: false,
  },
  {
    title: 'Digitizing Order',
    icon: 'mdi-hexagon-outline',
    role: [UserRoles.CUSTOMER],
    hasSubMenu: true,
    activeSubMenu: false,
    subMenuList: [
      {
        title: 'Create New Order',
        icon: 'fa-plus',
        route: '/digitizing/order/create',
      },
      {
        title: 'View All Orders',
        icon: 'fa-list-ul',
        route: '/digitizing/order/list',
      },
    ],
  },
  {
    title: 'Digitizing Quote',
    icon: 'mdi-hexagon',
    role: [UserRoles.CUSTOMER],
    hasSubMenu: true,
    activeSubMenu: false,
    subMenuList: [
      {
        title: 'Create New Quote',
        icon: 'fa-plus',
        route: '/digitizing/quote/create',
      },
      {
        title: 'View All Quotes',
        icon: 'fa-list-ul',
        route: '/digitizing/quote/list',
      },
    ],
  },
  {
    title: 'Vector Order',
    icon: 'mdi-guitar-pick-outline',
    role: [UserRoles.CUSTOMER],
    hasSubMenu: true,
    activeSubMenu: false,
    subMenuList: [
      {
        title: 'New Vector Order',
        icon: 'fa-plus',
        route: '/vector/order/create',
      },
      {
        title: 'View All Orders',
        icon: 'fa-list-ul',
        route: '/vector/order/list',
      },
    ],
  },
  {
    title: 'Vector Quote',
    icon: 'mdi-guitar-pick',
    role: [UserRoles.CUSTOMER],
    hasSubMenu: true,
    activeSubMenu: false,
    subMenuList: [
      {
        title: 'Create New Quote',
        icon: 'fa-plus',
        route: '/vector/quote/create',
      },
      {
        title: 'View All Quotes',
        icon: 'fa-list-ul',
        route: '/vector/quote/list',
      },
    ],
  },
  {
    title: 'Edits',
    icon: 'mdi-pencil-box-outline',
    role: [UserRoles.CUSTOMER],
    hasSubMenu: true,
    activeSubMenu: false,
    subMenuList: [
      {
        title: 'New Edit',
        icon: 'fa-plus',
        route: '/edit/create',
      },
      {
        title: 'View All Edits',
        icon: 'fa-list-ul',
        route: '/edit/list',
      },
    ],
  },
  {
    title: 'Billing',
    icon: 'mdi-credit-card',
    route: '/billing/list',
    role: [UserRoles.CUSTOMER],
    hasSubMenu: false,
    activeSubMenu: false,
  },
  {
    title: 'All Orders & Quotes',
    icon: 'mdi-format-list-bulleted',
    role: [UserRoles.ADMIN, UserRoles.CUSTOMER, UserRoles.MANAGER],
    route: '/all/orders-quotes-edits',
    hasSubMenu: false,
    activeSubMenu: false,
  },
  {
    title: 'Summary',
    icon: 'mdi-television-guide',
    role: [
      UserRoles.ADMIN,
      UserRoles.CUSTOMER,
      UserRoles.MANAGER,
      UserRoles.SENIOR_DIGITIZER,
      UserRoles.DIGITIZER,
    ],
    route: '/all/summary',
    hasSubMenu: false,
    activeSubMenu: false,
  },
  {
    title: 'Cancelled Orders and Quotes',
    role: [UserRoles.ADMIN, UserRoles.CUSTOMER, UserRoles.MANAGER],
    icon: 'mdi-close',
    route: '/all/cancelled',
    hasSubMenu: false,
    activeSubMenu: false,
  },
  {
    title: 'Contact Us',
    icon: 'fa-envelope',
    role: [UserRoles.CUSTOMER],
    href: 'https://dreamdigitizing.com/other-pages/contact.html',
    hasSubMenu: false,
    activeSubMenu: false,
  },
];
