import {
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  passwordMatch(control: AbstractControl) {
    if (control.parent) {
      if (control.value !== control.parent.get('password').value) {
        return { passwordMismatch: true };
      }
    }
  }

  emailValidator(control: AbstractControl) {
    // RFC 2822 compliant regex
    // tslint:disable-next-line
    if (
      control.value &&
      control.value.match(
        // tslint:disable-next-line
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
      )
    ) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  passwordValidator(control: AbstractControl) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    // (?!.*\s)          - Spaces are not allowed
    if (control.value.match(/^(?=.*\d)(?=.*[a-zA-Z!@#$%^&*])(?!.*\s).{6,100}$/)) {
      return null;
    } else {
      return { invalidPassword: true };
    }
  }

  mutBeTrue(control: AbstractControl) {
    if (control.value === true) {
      return null;
    } else {
      return { valueNotTrue: true };
    }
  }

  maxFileSizeValidator(size: number): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const maxFileSize: number = size * 1024 * 1024;
      if (control.value && control.value > maxFileSize) {
        return { maxFileSizeValidator: true };
      }
      return null;
    };
  }

  dateValidator(control: AbstractControl) {
    if (control.value && typeof control.value !== 'object') {
      return { invalidDate: true };
    }
  }

  dateRangeValidator(control: AbstractControl) {
    if (control.parent) {
      const from = control.parent.get('from').value;
      const to = control.parent.get('to').value;
      if (from && to) {
        const fromMonth = ('0' + (from.month - 1)).slice(-2);
        const toMonth = ('0' + (to.month - 1)).slice(-2);
        const fromDate = new Date(from.year, fromMonth as any, from.day);
        const toDate = new Date(to.year, toMonth as any, to.day);
        fromDate.setHours(0, 0, 0);
        toDate.setHours(23, 59, 59);
        const diffInMs = toDate.getTime() - fromDate.getTime();
        if (diffInMs <= 0) {
          return { invalidEndDate: true };
        }
      }
    }
    return null;
  }
}
