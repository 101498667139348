import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AttachmentService {
  constructor(private http: HttpClient) {}

  downloadAttachment(id: string): Promise<string> {
    return this.http.get<string>(`Attachment/Download/${id}`).toPromise();
  }

  uploadAttachment(payload: FormData): Promise<string> {
    return this.http.post<string>('Attachment/UploadTempFile', payload).toPromise();
  }
}
