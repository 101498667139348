import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  LoginUser,
  LoginResponse,
  RegisterUser,
  ForgotPasswordPayload,
  ResetPasswordPayload,
} from '../shared/interface/auth';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) {}

  login(user: LoginUser): Promise<LoginResponse> {
    return this.http.post<LoginResponse>('auth/login', user).toPromise();
  }

  register(user: RegisterUser): Promise<object> {
    return this.http.post<object>('auth/signUp', user).toPromise();
  }

  forgotPassword(payload: ForgotPasswordPayload): Promise<object> {
    return this.http.put<object>('auth/forgotPassword', payload).toPromise();
  }

  resetPassword(token: string, payload: ResetPasswordPayload): Promise<object> {
    return this.http.put<object>(`auth/ResetPassword/${token}`, payload).toPromise();
  }

  verifyEmail(payload): Promise<object> {
    return this.http.put<object>(`auth/EmailConfirmation`, payload).toPromise();
  }
}
