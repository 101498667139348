import { DataTableCoulmnTypes } from '../enums/data-table-activity';
import { APIOrderCategory, APIOrderTypes } from '../enums/order-types';
import { Column } from '../interface/table';

export const ORDER_ADMIN_COLUMNS: Column[] = [
  { key: 'fullName', name: 'Customer', type: DataTableCoulmnTypes.CUSTOMER },
  { key: 'agent', name: 'Agent', type: DataTableCoulmnTypes.CUSTOMER },
];

export const ORDER_CUSTOMER_COLUMNS: Column[] = [
  { key: 'paidOn', name: 'Payment Date', type: DataTableCoulmnTypes.DATE },
];

export const orderCategoryMap = {
  [APIOrderCategory.order]: 'order',
  [APIOrderCategory.quote]: 'quote',
};

export const orderTypeMap = {
  [APIOrderTypes.digitizing]: 'digitizing',
  [APIOrderTypes.vector]: 'vector',
  [APIOrderTypes.edit]: 'edit',
};

export const orderComission = {
  1: '30 Rs',
  2: '70 Rs',
  3: '100 Rs',
  4: '150 Rs',
  5: '200 Rs',
};

export const orderComissionValues = {
  1: 30,
  2: 70,
  3: 100,
  4: 150,
  5: 200,
};
