import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

// Services
import { StorageService } from '../service/storage.service';
import { ToasterService } from '../service/toaster.service';

// Constants
import { AUTH_CONSTANTS } from '../../shared/constant/auth';

// interfaces
import { LoggedInUser } from 'src/app/shared/interface/auth';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private toasterService: ToasterService,
    private storageService: StorageService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authorizedRole = route.data['roles'] as string[];
    const currentUser: LoggedInUser = this.storageService.getItem('user');
    if (currentUser && authorizedRole && !authorizedRole.includes(currentUser.roleId)) {
      this.toasterService.error(AUTH_CONSTANTS.roleNotAllowed);
      this.router.navigate(['/dashboard']);
    }
    return true;
  }
}
