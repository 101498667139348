import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

// Interfaces
import { TableItemActivity, Column } from '../../interface/table';

// Enums
import { DataTableActivity, DataTableCoulmnTypes } from '../../enums/data-table-activity';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-data-table-component',
  templateUrl: 'data-table.component.html',
})
export class DataTableComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() isCustomer: boolean;
  @Input() headerButtonTitle: string;
  @Input() columns: Column[];
  @Input() rows: any[];
  @Input() actions: string[];
  @Input() checkbox: boolean;
  @Input() detailsUrl: string;

  @Output() activityEvent: EventEmitter<TableItemActivity> = new EventEmitter();

  dataTableActivity: typeof DataTableActivity = DataTableActivity;
  dataTableCoulmnTypes: typeof DataTableCoulmnTypes = DataTableCoulmnTypes;

  followUp = {};
  followUpComments = {};
  customInput: Subject<any> = new Subject<any>();

  constructor() {}

  ngOnInit() {
    if (this.columns.find(column => column.type === DataTableCoulmnTypes.FOLLOW_UP)) {
      this.followUpUpdateEvent();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.columns.find(column => column.type === DataTableCoulmnTypes.FOLLOW_UP)) {
      return;
    }

    if (changes.rows && changes.rows.currentValue && changes.rows.currentValue.length) {
      for (const row of this.rows) {
        this.followUp[row.id] = row.followUp || '';
        this.followUpComments[row.id] = row.followUpComment;
      }
    }
  }

  activity(type: string, item?: any) {
    this.activityEvent.emit({
      type,
      item,
    });
  }

  followUpUpdateEvent() {
    this.customInput.pipe(debounceTime(1000)).subscribe(userId => {
      this.activityEvent.emit({
        type: DataTableCoulmnTypes.FOLLOW_UP,
        item: {
          id: userId,
          followUp: this.followUp[userId],
          followUpComment: this.followUpComments[userId] || '',
        },
      });
    });
  }

  updateFollowUp(userId: string) {
    this.customInput.next(userId);
  }
}
