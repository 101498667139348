import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

// Modules
import { InterceptorModule } from './interceptor/interceptor.module';
import { ShellModule } from '../shell/shell.module';

// Services
import { StorageService } from './service/storage.service';
import { EventEmitterService } from './service/event-emitter.service';
import { ValidationService } from './service/validation.service';
import { AuthService } from '../auth/auth.service';
import { ToasterService } from './service/toaster.service';
import { OrderService } from './service/order.service';
import { AttachmentService } from './service/attachment.service';
import { AppService } from './service/app.service';

// Components
import { AuthGuard } from './guards/auth.guard';
import { PublicGuard } from './guards/public.guard';
import { RoleGuard } from './guards/role.guard';

@NgModule({
  imports: [CommonModule, HttpClientModule, InterceptorModule, ShellModule],
  declarations: [],
  exports: [],
  providers: [
    AppService,
    AuthService,
    StorageService,
    ValidationService,
    AuthGuard,
    PublicGuard,
    RoleGuard,
    ToasterService,
    OrderService,
    AttachmentService,
    EventEmitterService,
  ],
})
export class CoreModule {}
