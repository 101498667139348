import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationExtras,
} from '@angular/router';
import { StorageService } from '../service/storage.service';
import { GuardService } from '../service/guard.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private guardService: GuardService,
    private storageService: StorageService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let navigationExtras: NavigationExtras;
    if (state.url !== '/error/404') {
      navigationExtras = {
        queryParams: { returnUrl: encodeURI(state.url) },
      };
    }
    const authToken = this.storageService.getItem('token');
    if (authToken) {
      return this.guardService
        .validateAuthorization()
        .then(response => {
          this.storageService.setItem('user', response);
          return true;
        })
        .catch(() => {
          return this.redirectToLogin(navigationExtras);
        });
    } else {
      return this.redirectToLogin(navigationExtras);
    }
  }

  private redirectToLogin(navigationExtras: NavigationExtras): boolean {
    this.storageService.remove('token');
    this.router.navigate(['/auth', 'login'], navigationExtras);
    return false;
  }
}
