import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { sidebarMenu } from './sidebar.menu';
import { StorageService } from '../../core/service/storage.service';
import { SideBarMenu } from './sidebar-menu.interface';
import { EventEmitterService } from 'src/app/core/service/event-emitter.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-sidebar-component',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit, OnDestroy {
  sidebarMenu: SideBarMenu[];
  toggleSidebar: boolean;
  toggleSubscription: Subscription;
  appUrl: string = environment.appUrl;

  constructor(
    private storageService: StorageService,
    private eventEmitterService: EventEmitterService,
  ) {}

  ngOnInit() {
    const currentUserRole = this.storageService.getItem('user').roleId;
    this.sidebarMenu = sidebarMenu.filter(
      menu => !menu.role || menu.role.includes(currentUserRole),
    );
    this.toggleSubscription = this.eventEmitterService.sidebarToggleEvent.subscribe(
      (value: boolean) => {
        this.toggleSidebar = value;
      },
    );
  }

  ngOnDestroy() {
    this.toggleSubscription.unsubscribe();
  }

  toggleMenu(menu: SideBarMenu) {
    if (menu.activeSubMenu) {
      menu.activeSubMenu = false;
    } else {
      this.sidebarMenu.map(item => {
        item.activeSubMenu = false;
      });
      menu.activeSubMenu = true;
    }
  }
}
