import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { RouteInterceptorService } from './core/service/route-interceptor.service';
import { fromEvent, Subscription } from 'rxjs';

import { EventEmitterService } from 'src/app/core/service/event-emitter.service';

import * as version from '../assets/version.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  hideSidebar = false;
  toggleSubscription: Subscription;
  resizeSubscription$: Subscription;
  windowWidth = window.innerWidth;

  constructor(
    private routeInterceptorService: RouteInterceptorService,
    private eventEmitterService: EventEmitterService,
  ) {}

  ngOnInit() {
    this.routeInterceptorService.initialize();
    const resizeObservable = fromEvent(window, 'resize');
    this.resizeSubscription$ = resizeObservable.subscribe(evt => {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 992) {
        this.hideSidebar = false;
      }
    });
    this.toggleSubscription = this.eventEmitterService.sidebarToggleEvent.subscribe(
      (value: boolean) => {
        if (this.windowWidth > 992) {
          this.hideSidebar = value;
        }
      },
    );
    this.checkIsVersionChange();
  }

  async checkIsVersionChange() {
    const savedVersion = localStorage.getItem('current-version');
    if (savedVersion !== version.version) {
      if (confirm('New version is pushed, please refresh your browser to get new version')) {
        localStorage.setItem('current-version', version.version);
        window.location.href = window.location.href;
      }
    }
  }

  ngOnDestroy() {
    this.toggleSubscription.unsubscribe();
  }
}
