import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

// Modules
import { ShellRoutingModule } from './shell.routes';

// Components
import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  imports: [CommonModule, RouterModule, ShellRoutingModule],
  entryComponents: [],
  declarations: [ShellComponent, HeaderComponent, SidebarComponent, FooterComponent],
  providers: [],
})
export class ShellModule {}
