import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { promise } from 'protractor';
import { DigitizingPayload } from '../../digitizing/digitizing.interface';
import {
  AssignOrderPayload,
  Order,
  OrderListItem,
  OrderSummary,
} from 'src/app/shared/interface/order';
import { CONFIG } from 'src/app/shared/constant/config';
import { APIOrderCategory, APIOrderTypes } from 'src/app/shared/enums/order-types';
import { ReleaseOption } from 'src/app/release/release.interface';
import { VectorPayload } from 'src/app/vector/vector.interface';
import { SearchPayload, SummaryInvoicePayload } from 'src/app/shared/interface/search';
import { addDetailsUrlInOrdersList } from 'src/app/shared/helpers/orders-map';

@Injectable()
export class OrderService {
  constructor(private http: HttpClient) {}

  getAllItems(): Promise<OrderListItem[]> {
    return this.http.get<OrderListItem[]>('order').toPromise();
  }

  getBaseParams(payload: SearchPayload) {
    let params: HttpParams = new HttpParams()
      .set('page.size', payload.pageSize.toString())
      .set('page.number', payload.pageNumber.toString())
      .set('sort', '-createdOn');
    if (payload.isReleased !== undefined) {
      params = params.set('filterequal.isReleased', payload.isReleased.toString());
    }
    if (payload.isConverted !== undefined) {
      params = params.set('filterequal.isConverted', payload.isConverted.toString());
    }
    if (payload.showCancelled) {
      params = params.set('filterequal.isCancelled', 'true');
    } else {
      params = params.set('filterequal.isCancelled', 'false');
    }
    if (payload.orderNumber !== undefined) {
      params = params.set('orderNumber', payload.orderNumber);
    }
    if (payload.orderName !== undefined) {
      params = params.set('searchText', payload.orderName);
    }
    if (payload.createdOnMin && payload.createdOnMax) {
      params = params.set('filtergreater.createdOn', payload.createdOnMax);
      params = params.set('filterless.createdOn', payload.createdOnMin);
    }
    if (payload.releasedOnMin && payload.releasedOnMax) {
      params = params.set('filtergreater.releasedOn', payload.releasedOnMax);
      params = params.set('filterless.releasedOn', payload.releasedOnMin);
    }
    return params;
  }

  getAllDigitizingOrders(payload: SearchPayload): Promise<OrderListItem[]> {
    let params = this.getBaseParams(payload);
    params = params.set('filterequal.category', APIOrderCategory.order);
    params = params.set('filterequal.type', APIOrderTypes.digitizing);
    return this.http
      .get<OrderListItem[]>('order/search', { params })
      .toPromise();
  }

  getAllDigitizingQuotes(payload: SearchPayload): Promise<OrderListItem[]> {
    let params = this.getBaseParams(payload);
    params = params.set('filterequal.category', APIOrderCategory.quote);
    params = params.set('filterequal.type', APIOrderTypes.digitizing);
    return this.http
      .get<OrderListItem[]>('order/search', { params })
      .toPromise();
  }

  getAllVectorOrders(payload: SearchPayload): Promise<OrderListItem[]> {
    let params = this.getBaseParams(payload);
    params = params.set('filterequal.category', APIOrderCategory.order);
    params = params.set('filterequal.type', APIOrderTypes.vector);
    return this.http
      .get<OrderListItem[]>('order/search', { params })
      .toPromise();
  }

  getAllVectorQuotes(payload: SearchPayload): Promise<OrderListItem[]> {
    let params = this.getBaseParams(payload);
    params = params.set('filterequal.category', APIOrderCategory.quote);
    params = params.set('filterequal.type', APIOrderTypes.vector);
    return this.http
      .get<OrderListItem[]>('order/search', { params })
      .toPromise();
  }

  getAllCancelledOrders(payload: SearchPayload): Promise<OrderListItem[]> {
    const params = this.getBaseParams(payload);
    return this.http
      .get('order/search', { params })
      .toPromise()
      .then(addDetailsUrlInOrdersList);
  }

  getAllEdits(payload: SearchPayload): Promise<OrderListItem[]> {
    let params = this.getBaseParams(payload);
    params = params.set('filterequal.type', APIOrderTypes.edit);
    return this.http
      .get<OrderListItem[]>('order/search', { params })
      .toPromise();
  }

  getAllOrders(payload: SearchPayload): Promise<OrderListItem[]> {
    const params = this.getBaseParams(payload);
    return this.http
      .get<OrderListItem[]>('order/search', { params })
      .toPromise()
      .then(addDetailsUrlInOrdersList);
  }

  getAllReleasedOrders(payload: SearchPayload): Promise<OrderListItem[]> {
    let params = this.getBaseParams(payload);
    params = params.set('filterequal.category', APIOrderCategory.order);
    return this.http
      .get<OrderListItem[]>('order/search', { params })
      .toPromise();
  }

  getLatestOrders(lastOrderTime: string): Promise<OrderListItem[]> {
    const params: HttpParams = new HttpParams()
      .set('page.size', '1')
      .set('page.number', '0')
      .set('sort', '-createdOn')
      .set('filtergreater.createdOn', lastOrderTime);
    return this.http
      .get<OrderListItem[]>('order/search', { params })
      .toPromise();
  }

  getAllPaidOrders(payload: SearchPayload) {
    const params: HttpParams = new HttpParams()
      .set('page.size', payload.pageSize.toString())
      .set('page.number', payload.pageNumber.toString())
      .set('sort', '-paidOn')
      .set('filterequal.isPaid', 'true')
      .set('filtergreater.paidOn', payload.createdOnMax)
      .set('filterless.paidOn', payload.createdOnMin);
    return this.http
      .get<OrderListItem[]>('order/search', { params })
      .toPromise();
  }

  getPadiOrdersInvoice(payload: SummaryInvoicePayload) {
    const params: HttpParams = new HttpParams()
      .set('filtergreater.createdOn', payload.createdOnMax)
      .set('filterless.createdOn', payload.createdOnMin);
    const httpOptions = {
      responseType: 'blob' as 'json',
      params,
    };
    return this.http.get<any>('order/summary-invoice', httpOptions).toPromise();
  }

  getById(id: number): Promise<Order> {
    return this.http.get<Order>(`order/${id}`).toPromise();
  }

  getUnpaidOrders(ids: string): Promise<Order[]> {
    let params: HttpParams = new HttpParams();
    if (ids) {
      params = params.set('orderIds', ids);
    }
    return this.http
      .get<Order[]>(`order/unpaid-orders`, { params })
      .toPromise();
  }

  getByNumber(orderNumber: string): Promise<Order> {
    return this.http.get<Order>(`order/${orderNumber}`).toPromise();
  }

  createDigitizingOrder(payload: DigitizingPayload): Promise<object> {
    return this.http.post<object>('order/digitizing', payload).toPromise();
  }

  createVectorOrder(payload: VectorPayload): Promise<object> {
    return this.http.post<object>('order/vector', payload).toPromise();
  }

  cancelOrder(orderId: number): Promise<object> {
    return this.http.put<object>(`order/${orderId}/cancel`, {}).toPromise();
  }

  payOrder(orderId: number): Promise<object> {
    return this.http.put<object>(`order/${orderId}/pay`, {}).toPromise();
  }

  payMultipleOrders(payload): Promise<object> {
    return this.http.put<object>(`order/pay/multiple`, payload).toPromise();
  }

  sendInvoice(Ids: number[]): Promise<object> {
    return this.http
      .post<object>(`order/send-invoice`, { Ids })
      .toPromise();
  }

  convertOrder(orderId: number): Promise<object> {
    return this.http.put<object>(`order/${orderId}/convert`, {}).toPromise();
  }

  convertOrderRequest(orderId: number): Promise<object> {
    return this.http.post<object>(`order/${orderId}/convert-request`, {}).toPromise();
  }

  assignDigitizer(orderId: number, payload: AssignOrderPayload): Promise<object> {
    return this.http.put<object>(`order/${orderId}/assign`, payload).toPromise();
  }

  releaseOrder(orderId: number, payload): Promise<object> {
    return this.http.post<object>(`order/${orderId}/release`, payload).toPromise();
  }

  confirmReleaseOrder(orderId: number): Promise<object> {
    return this.http.put<object>(`order/${orderId}/confirm-release`, {}).toPromise();
  }

  undoReleaseOrder(orderId: number): Promise<object> {
    return this.http.put<object>(`order/${orderId}/undo-release`, {}).toPromise();
  }

  getOrdersSummary(date: string): Promise<OrderSummary> {
    return this.http.post<OrderSummary>(`order/summary/${date}`, {}).toPromise();
  }

  markOrderAsPaid(orderId: number): Promise<object> {
    return this.http.put<object>(`order/${orderId}/paid`, {}).toPromise();
  }
}
