import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// Services
import { ToasterService } from '../service/toaster.service';

// Constants
import { AUTH_CONSTANTS } from '../../shared/constant/auth';
import { TOAST_MESSAGES } from 'src/app/shared/constant/messages';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private toasterService: ToasterService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
            // console.log('response', response);
          }
        },
        (error: any) => {
          // do error handling here
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this.toasterService.error(AUTH_CONSTANTS.tokenExpired);
              localStorage.removeItem('token');
              this.router.navigate(['/auth', 'login']);
            } else {
              if (error.error['Error']) {
                this.toasterService.error(error.error['Error']);
              } else if (error.error.Message) {
                this.toasterService.error(error.error.Message);
              } else if (error.error.message) {
                this.toasterService.error(error.error.message);
              } else {
                this.toasterService.error(TOAST_MESSAGES.somethingBadHappened);
              }
            }
          }
        },
      ),
    );
  }
}
