export const TOAST_MESSAGES = {
  somethingBadHappened: 'Something bad happened.',
  registerSuccess:
    'Register Successful. The verification link has been sent to your email. Please verify your email.',
  forgotPassworSuccess:
    'An email has been sent to your email address please reset your password with hash code from email.',
  forgotPassworError: 'Forgot password link invalid',
  verifyEmailSuccess: 'Your email has been verified.',
  verifyEmailError: 'Verification code invalid.',
  resetPasswordError: 'Invalid reset password link',
  resetPasswordSuccess: 'Your password has been reset.',
  userUpdateSuccess: 'User has been updated.',
  profileUpdateSuccess: 'Your profile has been updated.',
  salesAgentCreateSuccess: 'Sales agent has been created.',
  salesAgentUpdateSuccess: 'Sales agent has been updated.',
  DigitizingOrderSuccess: 'Your digitizing order has been placed.',
  DigitizingQuoteSuccess: 'Your digitizing quote has been placed.',
  VectorOrderSuccess: 'Your vector order has been placed.',
  VectorQuoteSuccess: 'Your vector quote has been placed.',
  DigitizingOrderSuccessEdit: 'Digitizing order has been updated.',
  DigitizingQuoteSuccessEdit: 'Digitizing quote has been updated.',
  VectorOrderSuccessEdit: 'Vector order has been updated.',
  VectorQuoteSuccessEdit: 'Vector quote has been updated.',
  EditCreateSuccess: 'Your edit has been placed.',
  changePasswordSuccess: 'Your password has been updated.',
  invlidOrderIds: 'Invalid Order Ids',
  orderPaidSuccess: 'Your order has been paid.',
  orderAlreadyPaid: 'Order already paid',
  orderConvertSuccess: 'Your quote has been converted to order.',
  orderConvertRequestSuccess: 'Your quote converted to order email has been sent.',
  orderConfirmReleaseSuccess: 'Your order has been released.',
  orderUndoReleaseSuccess: 'Your order has been undo from released.',
  orderCancelSuccess: 'Your order has been cancelled.',
  quoteCancelSuccess: 'Your quote has been cancelled.',
  editCancelSuccess: 'Your edit has been cancelled.',
  orderDeleteSuccess: 'Your order has been deleted.',
  quoteDeleteSuccess: 'Your quote has been deleted.',
  editDeleteSuccess: 'Your edit has been deleted.',
  orderReleaseSuccess: 'Your order has been released.',
  quoteReleaseSuccess: 'Your quote has been released.',
  editReleaseSuccess: 'Your edit has been released.',
  releaseUpdateSuccess: 'Release has been updated.',
  orderReleaseFileSelectError: 'One of more selected files are invalid, max 40mb size allowed',
  newOrdersReceived: 'New orders received',
  orderInvoiceSent: 'Your invoice has been sent.',
  invoiceDownloadSuccess: 'Invoice downloaded successfully.',
  markOrderAsPaidSuccess: 'Order has been paid.',
  feedbackEmailSent: 'Feedback email has been sent.',
  followUpSuccess: 'Follow up has been saved.',
  orderDigitizerAssignSuccess: 'Digitizer has been assigned.',
};
