import { Injectable } from '@angular/core';
import { PROTECTED_STORAGE_ITEMS } from '../../shared/constant/auth';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private memoryInfo: any;
  private protectedInformation: string[];

  constructor() {
    this.protectedInformation = PROTECTED_STORAGE_ITEMS;
    this.memoryInfo = {};
  }

  getItem(item: string) {
    if (this.protectedInformation.indexOf(item) >= 0) {
      return this.memoryInfo[item];
    }
    return localStorage.getItem(item);
  }

  setItem(item: string, value: any) {
    if (this.protectedInformation.indexOf(item) >= 0) {
      this.memoryInfo[item] = value;
    } else {
      localStorage.setItem(item, value);
    }
  }

  remove(item: string) {
    this.memoryInfo[item] = null;
    localStorage.removeItem(item);
  }

  clear() {
    this.memoryInfo = {};
    return localStorage.clear();
  }
}
