import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationExtras,
} from '@angular/router';
import { StorageService } from '../service/storage.service';
import { GuardService } from '../service/guard.service';

@Injectable()
export class PublicGuard implements CanActivate {
  constructor(
    private router: Router,
    private guardService: GuardService,
    private storageService: StorageService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authToken = this.storageService.getItem('token');
    if (authToken) {
      return this.guardService
        .validateAuthorization()
        .then(response => {
          this.storageService.setItem('user', response);
          this.router.navigate(['/dashboard']);
          return false;
        })
        .catch(() => {
          return true;
        });
    } else {
      return true;
    }
  }
}
