import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GuardService {
  constructor(private http: HttpClient) {}

  validateAuthorization(): Promise<any> {
    return this.http.get<any>('user/me').toPromise();
  }
}
