import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class EventEmitterService {
  @Output()
  sidebarToggleEvent: EventEmitter<boolean> = new EventEmitter();

  // Event to update headline workExperience
  emitSidebarToggleChange(value: boolean): void {
    this.sidebarToggleEvent.emit(value);
  }
}
