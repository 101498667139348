import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SomethingBadHappenedComponent } from './something-bad-happened/something-bad-happened.component';

// Modules
import { SharedModule } from './../shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SomethingBadHappenedComponent, PageNotFoundComponent],
})
export class ErrorModule {}
