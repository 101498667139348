import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouteInterceptorService {
  private previousUrl: string;
  private currentUrl: string;
  private routeHistory: string[] = [];

  constructor(private router: Router) {}

  initialize() {
    // To navigate to the same route with different query params
    // Angular router does not support by default navigation to the same route
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.router.navigated = false;
        window.scroll(0, 0);
        this.setURLs(event);
      });
  }

  private setURLs(event: NavigationEnd): void {
    const tempUrl = this.currentUrl;
    this.previousUrl = tempUrl;
    this.currentUrl = event.urlAfterRedirects;
    this.routeHistory.push(event.urlAfterRedirects);
  }

  getPreviousUrl(): string {
    return this.previousUrl;
  }

  getCurrentUrl(): string {
    return this.currentUrl;
  }

  getRouteHistory(): string[] {
    return this.routeHistory;
  }
}
