import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Third party
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// Shared components
import { ValidationComponent } from './component/validation/validation.component';
import { DataTableComponent } from './component/data-table/data-table.component';
import { FirstKeyPipe } from './component/validation/first-key-validation.pipe';

// Modals
import { ConfirmModalComponent } from './modal/conform-modal/confirm-modal.component';

@NgModule({
  declarations: [ValidationComponent, FirstKeyPipe, DataTableComponent, ConfirmModalComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgProgressModule.forRoot(),
    NgProgressRouterModule,
    NgProgressHttpModule,
    ToastrModule.forRoot(),
    NgbModule,
    InfiniteScrollModule,
    FormsModule,
  ],
  providers: [],
  exports: [
    DataTableComponent,
    ValidationComponent,
    NgProgressModule,
    NgProgressRouterModule,
    NgProgressHttpModule,
    NgbModule,
    ConfirmModalComponent,
    InfiniteScrollModule,
  ],
  entryComponents: [ConfirmModalComponent],
})
export class SharedModule {}
