import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/core/service/storage.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent implements OnInit {
  isLoggedIn = false;

  constructor(private router: Router, private storageService: StorageService) {}

  ngOnInit(): void {
    const authToken = this.storageService.getItem('token');
    if (authToken) {
      this.isLoggedIn = true;
    }
  }

  goBack(): void {
    if (this.isLoggedIn) {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/auth/login']);
    }
  }
}
