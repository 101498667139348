import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal-component',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
  message: string;
  successText: string;
  errorText: string;

  constructor(private ngbActiveModal: NgbActiveModal) {}

  close() {
    this.ngbActiveModal.close();
  }

  cancel() {
    this.ngbActiveModal.dismiss();
  }
}
